<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <form [formGroup]="form" class="flex flex-col gap-1">
        <mat-form-field class="chip-list" subscriptSizing="dynamic">
            <mat-label>{{ 'alert.action.text.numbers' | translate }}</mat-label>
            <mat-chip-grid #chipGrid>
                <mat-chip-row
                    *ngFor="let phoneNumber of phoneNumbers"
                    (removed)="remove(phoneNumber)"
                >
                    {{ phoneNumber }}
                    <button matChipRemove>
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                </mat-chip-row>
                <input
                    formControlName="text"
                    type="phone"
                    [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)"
                />
            </mat-chip-grid>
        </mat-form-field>
        <div class="text-xs">
            {{ 'alert.phone_number_msg' | translate }}
        </div>
        <div class="mat-subtitle-2">
            {{ 'alert.action.text.message' | translate }}
        </div>
        <sz-input
            #textMessage
            formControlName="textMessage"
            maxlength="1600"
            required
        />
        <div class="text-xs">
            {{ 'alert.interpolation_msg' | translate }}
            <br />
            <a
                class="text-primary-500"
                [href]="docsUrl"
                (click)="dialogRef.close()"
                >{{ 'help.user_documentation' | translate }}</a
            >
        </div>
        <mat-hint> {{ form.get('textMessage').value.length }} / 1600 </mat-hint>
        @if (data.isGeoFence) {
            <div>
                <div class="mat-subtitle-2">
                    {{ 'alert.action.when' | translate }}
                </div>
                <sz-select [formControl]="when" [options]="whenOptions" />
            </div>
        }
    </form>
    <sz-dialog-button-bar
        [primaryDisabled]="
            form.invalid ||
            phoneNumbers.length === 0 ||
            (isGeoFence && !when.valid)
        "
        (primaryClick)="confirm()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
